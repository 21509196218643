<script>
import Axios from 'axios';

export default { 

  async Post(config, url){ 

    var object = [];
    let urlBase = config.urlBase + url;
    let body = config.body;
    let headers = config.headers;

    await Axios
      .post( urlBase, body, { headers } )
      .then((response) => {
        object = response.data;
        
      }).catch((response) => {
        object = response.data;

      });

      return object;
  },


  // Put(config){
  //   Axios
  //     .post( urlBase + url, body, { headers } )
  //     .then((response) => {

  //     });
  // },

  // get(config){
  //   Axios
  //     .post( urlBase + url, body, { headers } )
  //     .then((response) => {

  //     });
  // },

  // GetAll(config){
  //   Axios
  //     .post( urlBase + url, body, { headers } )
  //     .then((response) => {

  //     });
  // },

  async GetById(config, url, id){

  var object = [];
  let urlBase = config.urlBase + url;
  let headers = config.headers;

  await 
    Axios
      .get( urlBase + id, { headers } )
      .then((response) => {
        object = response.data;
        
      }).catch((response) => {
        object = response.data;

    });    

    return object;
  },

  // Remove(config){
  //   Axios
  //     .post( urlBase + url, body, { headers } )
  //     .then((response) => {

  //     });
  // }

};

</script>


