<template>
  <div class="alertComponente">

    <b-alert
      fade
      :show="time"
      dismissible
      :variant="color"
      @dismissed="time=0"
      @dismiss-count-down="countDownChanged"
    >
    <div class="labelProgress">
      <p class="labelAlert"> {{ message }} </p>
      <b-progress
        :variant="color"
        max="5"
        :value="time"
        height="4px"
      ></b-progress>
    </div>
    </b-alert>

  </div>
</template>

<script>
  export default {
    name: 'Alert',
    data: () => ({
      color: '',
      message: '',
      time: 0,
    }),
    created () {
      this.$root.$on('Notification', payload => {
      this.color = payload.color
      this.message = payload.message
      this.time = payload.time
    })
    
    },
    methods: {
      countDownChanged(time) {
        this.time = time
      },
    }
  }
</script>

<style scoped>
/* @import '../style/Alert.css'; */
</style>