<template>
  <html class="Formulary">
    
    <Alert />
    <picture>
      <source id="imgMobile" class="formularyBackground" alt="BackgroudFormulary-Mobile" srcset="../../assets/Screen2-Mobile-Default.png" media="(max-width: 1024px) and (orientation: portrait)">
      <!-- <source alt="BackgroudFormulary-Desktop<1920" srcset="../../assets/Background_Tela_2-01.png" media="(min-width: 1920px) and (max-height: 969px) and (orientation: landscape)">
      <source alt="BackgroudFormulary-Desktop<1366" srcset="../../assets/Screen2-Desktop-Default.png" media="(min-width: 1366px) and (min-height: 768px) and (orientation: landscape)">
      <source alt="BackgroudFormulary-Desktop1366" srcset="../../assets/Background_Tela_2-01.png" media="(width: 1366px) and (orientation: landscape)">
      <source alt="BackgroudFormulary-Desktop1920" srcset="../../assets/Screen2-Desktop-Default.png" media="(min-width: 1920px) and (orientation: landscape)">-->
      <source alt="BackgroudFormulary-Desktop <= 1180" srcset="../../assets/Screen2-Desktop-Default.png" media="(max-width: 1024px) and (orientation: landscape)">
      <source alt="BackgroudFormulary-Desktop = 1366" srcset="../../assets/Background_Tela_2-01.png" media="(max-width: 1366px) and (orientation: landscape)">
      <img id="idImgFormulary" class="idImgFormulary" alt="Sempre IT - Formulary" src="../../assets/Screen2-Desktop-Default.png" max-width="100%">
    </picture>

    <div class="spin" v-if="spinner">
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
      
  <div class="container">

    <div v-if="isSingleQuestion || isMultipleQuestion || isEssayQuestion || isMultipleRadioQuestion" class="containerAnswer" id="idContainerAnswer">

      <div class="form-group describeProject">
        <div v-if="isProject" class="project ">
          Projeto
        </div>
        <div v-else class="project ">
          
        </div>

        <div class="codeProject">
          {{ this.nameProject }}
        </div>

        <div class="responsible">
          Prezado(a) 
          <label class="nameHead">
            {{ this.nameClient }}
          </label>
        </div>
      </div>

      <div class="form-group containerForm" v-for="item in listQuestions" :key="item.id">
        <form id="idForm" >

          <!-- CAMPO TEXT ÁREA -->
          <div class="form-group" v-if="item.IdTipoPergunta === 1">
            <div class="form-check divTextarea">
              <label class="labelTextarea" for="Textarea"> 
                {{ item.DescPergunta }} 
              </label>
              <textarea class="form-control formTextarea" id="formTextarea" name="formTextarea" rows="3" v-model="item.OpcaoPergunta"
                @change="sendEssayAnswer(item.IdRespostaPesquisa, item.IdPergunta, item.IdTipoPergunta, item.OpcaoPergunta)">
              </textarea>
            </div>
          </div>

          <!-- CAMPOS DE ESCOLHA ÚNICA (Númerico) -->
          <div class="form-group" v-if="item.IdTipoPergunta === 2">
            <div class="form-check divAnswers">
              <div class="form-check form-check-inline">
                <div class="divRadio">
                    <div class="form-group descriptionQuestion">
                      <label class="form-check-label labelRadio" for="Radio">
                        {{ item.DescPergunta }} 
                      </label>
                    </div>
                    <div class="form-group">
                      <div class="inline-radio radioRow">
                          <div class="divInput" v-for="(n, index) in item.OpcaoPergunta[0].OpcoesPerguntaUnica" :key="index">
                            <input class="input-inline" id="idInput" name="formSingle" type="radio"
                              @click="sendSilgleAnswer(item.IdRespostaPesquisa, item.IdPergunta, item.IdTipoPergunta, n)">
                            <label class="singleQuestion" id="formSingle">
                              {{ n }}
                            </label>
                          </div>

                      </div>
                      <div class="form-group">
                            <div class="labelExtremes">
                              <span v-for="i in item.OpcaoPergunta[0].Extremos" :key="i" class="text--white spanRadio"> 
                                {{ i }}
                              </span>
                            </div>
                          </div>
                    </div>

                </div>
              </div>
            </div>
          </div>

          <!-- CAMPOS DE MÚLTIPLA ESCOLHA -->
          <div class="form-group" v-if="item.IdTipoPergunta === 3">
              <div class="form-check divAnswers divMultiple">
                <div class="form-group multiple">
                  <label class="form-check-label" for="LabelRadio">
                    {{ item.DescPergunta }} 
                  </label>
                </div>
                <div class="bodyOptions form-group" id="idMultiple">
                  <div class="form-check form-check-inline">
                    <tbody class="bodyInput">
                      <tr v-for="(i, index) in item.OpcaoPergunta[0].OpcoesPerguntaMultipla" :key="index">
                        <div class="inputMultiple">
                          <input class="form-check-input boxMultiple" id="formMultiple" name="formMultiple" type="checkbox" :value="i" 
                            @change="sendMultipleAnswer(index, item.IdRespostaPesquisa, item.IdPergunta, item.IdTipoPergunta, i)">
                        </div>
                        <!-- <td> -->
                          <div class="labelMultiple">
                            <label class="form-check-input labelQuestionOptions" for="flexCheckDefault"> 
                              {{ i }}
                            </label>
                          </div>
                        <!-- </td> -->
                      </tr>
                    </tbody>
                  </div>
                </div>
              </div>
          </div>

          <!-- CAMPOS DE ESCOLHA ÚNICA (Texto) -->
          <div class="form-group" v-if="item.IdTipoPergunta === 4">
            <div class="form-check divAnswers">
              <div class="divRadio divRadioText">
                <div class="form-group">
                  <label class="form-check-label labelRadio" for="Radio">
                    {{ item.DescPergunta }} 
                  </label>
                </div>

                <div class="form-group groupSingleText">
                  <div class="inputSingleText" v-for="(n, index) in item.OpcaoPergunta[0].OpcoesPerguntaMultipla" :key="index">
                    <input class="sizeRadioText" name="formSingleText" type="radio"
                      @click="sendSilgleAnswer(item.IdRespostaPesquisa, item.IdPergunta, item.IdTipoPergunta, n)">
                    <label class="ml-1 labelSingleText">
                      {{ n }}
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </div>
            
          

        </form>
      </div>

      <div class="form-group">
        <div class="buttonFormulary">
          <button v-if="loading" id="idButtonFormulary" type="button" class="btn btn-outline-secondary btn-lg mb-2" @click="sendForm()">
            <b-spinner small type="grow"></b-spinner>
            Carregando...
          </button>
          <button v-else id="idButtonFormulary" type="button" class="btn btn-outline-secondary btn-lg mb-2" @click="sendForm()">
            ENVIAR
          </button>
        </div>
      </div>


    </div>

  </div>
  </html>

</template>

<script>
import helper from '../Utilities/Helper.vue';
import service from '../service/services.vue';
import Alert from '../Utilities/Alert.vue';

export default {
  name: 'Formulary',
  components: {
    Alert,
  },

  data() {
    return {
      loading: false,
      spinner: true,
      isSingleQuestion: false,
      isMultipleQuestion: false,
      isMultipleRadioQuestion: false,
      isEssayQuestion: false,
      isProject: false,
      listQuestions: [],
      idSearch: "",
      essayAnswers: [],
      multipleAnswers: [],
      singleAnswers: [],
      nameProject: "",
      nameClient: "",
      hash: "",
      qtdSingleQuestion: 0,
      qtdMultipleQuestion: 0,
      qtdEssayQuestion: 0,
      qtdMultipleRadioQuestion: 0,
    }
  },

  mounted(){},

  created() {
    this.hash = localStorage.getItem('hash');
    this.getAnswers();

  },

  methods: {

    async getAnswers(){
      const configuration = helper.getConfiguration();
      const hash = configuration.body.hash;
      var answers = await service.GetById(configuration, 'api/v1/Search/Question/', hash)

      if(answers.Data.length > 0){
        if(answers.Success){
          this.listQuestions = answers.Data;

          this.listQuestions[0].NomeProjetoCliente.length > 0 ? this.nameProject = this.listQuestions[0].NomeProjetoCliente : this.nameProject = this.listQuestions[0].NomeProjeto;
          this.listQuestions[0].NomeCliente === null ? this.nameClient = this.listQuestions[0].NomeColaborador : this.nameClient = this.listQuestions[0].NomeCliente; 

          this.hash = hash;

          this.listQuestions.forEach((item) => {

            if(item.NomeProjeto !== ''){
              this.isProject = true;
            }
            
            if(item.IdTipoPergunta === 1){
              this.isEssayQuestion = true;
              this.qtdEssayQuestion += 1;

            }else if(item.IdTipoPergunta === 2){
              this.isSingleQuestion = true;
              this.qtdSingleQuestion += 1;

            }else if(item.IdTipoPergunta === 3){
              this.isMultipleQuestion = true;
              this.qtdMultipleQuestion += 1;

            }else if(item.IdTipoPergunta === 4){
              this.isSingleQuestion = true;
              this.qtdSingleQuestion += 1;

            }
          });

          this.spinner = false;

        }
        else{
          this.$root.$emit('Notification', {
            color: 'warning',
            message: answers.Message,
            time: 5
          });
        }
      }else{
        this.$root.$emit('Notification', {
          color: 'warning',
          message: 'Authentication failed, redirecting ...',
          time: 3
        });

        setTimeout(() => {
          history.go(-1);
        },3200);

      }
      
      //console.log('DADOS', this.listQuestions);
    },  

    sendEssayAnswer(idAnswerSearch, idQuestion, idKindQuestion, valueEssayAnswer){
      if(this.essayAnswers.length === 0){
        this.essayAnswers.push({
          id_resposta_pesquisa: idAnswerSearch,
          id_pergunta: idQuestion,
          id_tipo_pergunta: idKindQuestion,
          valor_resposta: valueEssayAnswer,
        });

      }else{

        const existe = this.essayAnswers.find(c => c.id_pergunta === idQuestion);

        if(existe === undefined){
          this.essayAnswers.push({
          id_resposta_pesquisa: idAnswerSearch,
          id_pergunta: idQuestion,
          id_tipo_pergunta: idKindQuestion,
          valor_resposta: valueEssayAnswer,
        });

        }else{

          if(valueEssayAnswer !== ""){
            let index = this.singleAnswers.indexOf(existe);
            this.essayAnswers.splice(index, 1);
            this.essayAnswers.push({
              id_resposta_pesquisa: idAnswerSearch,
              id_pergunta: idQuestion,
              id_tipo_pergunta: idKindQuestion,
              valor_resposta: valueEssayAnswer,
            });

          }else{
            this.essayAnswers.splice(existe , 1);
          }
        }
      }

    },

    sendMultipleAnswer(index, idAnswerSearch, idQuestion, idKindQuestion, valueMultipleAnswer){
      
      if(this.multipleAnswers.length === 0){
        this.multipleAnswers.push({
          index: index,
          id_resposta_pesquisa: idAnswerSearch,
          id_pergunta: idQuestion,
          id_tipo_pergunta: idKindQuestion,
          valor_resposta: valueMultipleAnswer,
          status: true,
        });

      }else{

        const existe = this.multipleAnswers.find(c => c.id_pergunta === idQuestion && c.index === index );
        
        if(existe === undefined){
          this.multipleAnswers.push({
            index: index,
            id_resposta_pesquisa: idAnswerSearch,
            id_pergunta: idQuestion,
            id_tipo_pergunta: idKindQuestion,
            valor_resposta: valueMultipleAnswer,
            status: true,
          });

        }else{
          let idx = this.multipleAnswers.indexOf(existe);
          this.multipleAnswers.splice(idx, 1);

        }
      }
    },

    sendSilgleAnswer(idAnswerSearch, idQuestion, idKindQuestion, valueSingleAnswer){
      
      if(this.singleAnswers.length === 0){
        this.singleAnswers.push({
          id_resposta_pesquisa: idAnswerSearch,
          id_pergunta: idQuestion,
          id_tipo_pergunta: idKindQuestion,
          valor_resposta: valueSingleAnswer,
        });

      }else{

        const existe = this.singleAnswers.find(c => c.id_pergunta === idQuestion);

        if(existe === undefined){

          this.singleAnswers.push({
          id_resposta_pesquisa: idAnswerSearch,
          id_pergunta: idQuestion,
          id_tipo_pergunta: idKindQuestion,
          valor_resposta: valueSingleAnswer,
        });

        }else{

          let index = this.singleAnswers.indexOf(existe);

          this.singleAnswers.splice(index, 1);
          this.singleAnswers.push({
            id_resposta_pesquisa: idAnswerSearch,
            id_pergunta: idQuestion,
            id_tipo_pergunta: idKindQuestion,
            valor_resposta: valueSingleAnswer,
          });
        }
      }

      this.idSearch = idAnswerSearch;
    },
    
    validation(singleAnswers, multipleAnswers, essayAnswers){

      var isValid = false;
      
      if( singleAnswers.length !== this.qtdSingleQuestion 
          || multipleAnswers.length < this.qtdMultipleQuestion 
          || essayAnswers.length !== this.qtdEssayQuestion )  {
        isValid = true;

      }
      
      return isValid;
    },

    async sendForm(){
      var isValid = this.validation(this.singleAnswers, this.multipleAnswers, this.essayAnswers);

      if(isValid){
        this.$root.$emit('Notification', {
          color: 'warning',
          message: 'Todas perguntas devem ser respondidas!',
          time: 5
        });

      }else{
        this.loading = true;

        let searchAnswers = {
          idSearch: this.idSearch,
          hash: this.hash,
          singleAnswers: this.singleAnswers,
          multipleAnswers: this.multipleAnswers,
          essayAnswers: this.essayAnswers,
        };

        const configuration = helper.getConfiguration();
        configuration.body.hash = this.hash;
        configuration.body = searchAnswers;

        var result = await service.Post(configuration, 'api/v1/Search/Answers');
        
          this.loading = true;
          if(result.Success){
            this.$root.$emit('Notification', {
              color: 'success',
              message: result.Message,
              time: 5
            });
            window.location.href = "/congratulations";

          }else{
            this.loading = false;
            this.$root.$emit('Notification', {
              color: 'warning',
              message: result.Message,
              time: 5
            });
          }

      }

    },
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../style/Formulary.css';

</style>
