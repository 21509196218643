import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    item: []

  },
  mutations: {

    //Adiciono na lista para ter seu estado global no ciclo de vida da aplicação
    // addList: (state, payload) => {

    //   state.item = payload;
    //   //console.log(state.item);

    // }

  },
  actions: { 

  }
})
