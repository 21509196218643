import Vue from 'vue'
import App from './App.vue'
import service from './components/service/js/Authorization.js'
import router from './components/service/js/router.js'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { SpinnerPlugin } from 'bootstrap-vue'

//Importando fontawesome for the project
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//Importando Vuex (gerenciador de estado)
import store from './store'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
//seto service no início da aplicação para pegar token de autenticação
Vue.use(service)
//Importo spinner pra usar no loadings dos botões
Vue.use(SpinnerPlugin)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
