import Axios from "axios"

  const inputhAut = {
    'Login': 'U2VtcHJlSVRAMjAyMQ==',
    'Password': 'U2VhcmNoQmlnU2VtcHJlSVRAXzIwMjE='
  }

  const token =
  Axios
    .post(process.env.VUE_APP_ENVI +"api/Authorization", inputhAut)
    .then(response => {
      //return response.data.Data.Token;
      localStorage.setItem('token', response.data.Data.Token)
      const hash = window.location.hash.split('#')[1];
      localStorage.setItem('hash', hash)
    })
    .catch((error) => {
      console.error("Ops! ocorreu um erro" + error);
    });

    export default token;
