import Vue from 'vue'
import Router from 'vue-router'

import Home from '../../view/Home.vue'
import Formulary from '../../view/Formulary.vue'
import Congratulations from '../../view/Congratulations.vue'

Vue.use(Router)

const routes = [
  {
    name: 'home',
    path: '',
    component: Home
  },
  {
    name: 'formulary',
    path: '/formulary',
    component: Formulary,
  },
  {
    name: 'congratulations',
    path: '/congratulations',
    component: Congratulations,
  },

]

const router = new Router({
  mode: "history",
  routes
}) 

export default router