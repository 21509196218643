<script>

export default {

  getConfiguration(){
    var urlBase = process.env.VUE_APP_ENVI;
    const tempToken = localStorage.getItem('token');
    const hash = localStorage.getItem('hash');
    const body = { 'hash': hash };
    const headers = {
      'Authorization': 'Bearer' + ' ' + tempToken,
      'My-Custom-Header': 'foobar'
    };

    const configuration = {
      'urlBase': urlBase,
      'body': body,
      'headers': headers
    }
    
    return configuration;
  },

}

</script>