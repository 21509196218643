<template>
  <div class="home">

    <Alert />
    <picture>
      <source class="mobile" srcset="../../assets/Screen-1-SearchMobile-min(s-r).png" media="(max-width: 1024px) and (orientation: portrait)" alt="Background Mobile">
      <!-- <source class="mobileLandscape" srcset="../../assets/tela1-min(s-r).jpg" media="(max-width: 1280px) and (orientation: landscape)" alt="Background Mobile">
      <source class="desktop937" srcset="../../assets/Tela_1.png" media="(min-width: 1900px) and (max-width: 1920px) and (min-height: 935px) and (max-height: 969px)" alt="Background Desktop 560w á 1366w">
      <source srcset="../../assets/tela1-min(s-r).jpg" media="(min-width: 560px) and (max-width: 1366px) and (min-height: 1000px) and (max-height: 1024px) and (orientation: landscape)" alt="Background Desktop 560w á 1366w">
      <source srcset="../../assets/tela1-min(s-r).jpg" media="(min-width: 1920px) and (max-height: 1080px) and (orientation: landscape)" alt="Background Desktop até 1920w">
      <source srcset="../../assets/tela1-min(s-r).jpg" media="(min-width: 1920px) and (orientation: landscape)" alt="Background Desktop > 1920w">
      <source srcset="../../assets/tela1-min(s-r).jpg" media="(min-width: 1367px) and (max-width: 1902px) and (orientation: landscape)" alt="Background Desktop < 1920w"> -->
      <source srcset="../../assets/Tela_1.png" media="(min-width: 1181px) and (max-width: 1366px) and (orientation: landscape)" alt="Background Desktop <= 1366">
      <source srcset="../../assets/tela1-min(s-r).jpg" media="(max-width: 1180px) and (orientation: landscape)" alt="Background Desktop <= 1180">
      <img class="imgHome" alt="Sempre IT - Home" src="../../assets/tela1-min(s-r).jpg" max-width="100%">
    </picture>
    
      <button v-if="loading" id="idButton" type="button" class="btn btn-outline-secondary btn-lg mb-2" @click="verification()">
          <b-spinner class="pLoading" small type="grow"></b-spinner>
          <span class="spanLoading"> Carregando... </span> 
      </button>
      
      <button v-else id="idButton" type="button" class="btn btn-outline-secondary btn-lg mb-2" @click="verification()">
        RESPONDER
      </button>
      
  </div>
</template>

<script>
import helper from '../Utilities/Helper.vue';
import request from '../service/services.vue';
import Alert from '../Utilities/Alert.vue';

export default {
  name: 'Home',
  components: {
    Alert,
  },
  props: {
    msg: String
  },

  data:() => {
    return {
      loading: false,
    }
  },
  
  methods:{

    async verification() {

      this.loading = true;

      const configuration = helper.getConfiguration();

      var result = await request.Post(configuration, 'api/v1/Search/VerificationAnswer');
        
        if(result === undefined){
          this.loading = false;

          this.$root.$emit('Notification', {
            color: 'warning',
            message: 'Erro no servidor interno!',
            time: 5
          });
        }
        else if(result.Success){
          window.location.href = "/formulary";
          
        }else{
          this.$root.$emit('Notification', {
            color: 'warning',
            message: result.Message,
            time: 5
          });

        }

      this.loading = false;
    },


    
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../style/Alert.css';
@import '../style/Home.css';
</style>
