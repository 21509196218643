<template>
  <div class="congratulations">

    <picture>
      <source id="imgMobileCongrat" class="imgBackgroundMob" alt="BackgroudCongratulations-Mobile" srcset="../../assets/Screen-3-SearchMobile-min(s-e).png" media="(max-width: 1024px) and (orientation: portrait)">
      <!-- <source id="imgDesktop1920Congrat" class="imggroundDesk1" alt="imgCongratulations-Desktop<1920" srcset="../../assets/Background_Tela_3(s-e).png" media="(max-width: 1920px) and (min-height: 937px) and (max-height: 969px) and (orientation: landscape)">
      <source id="imgDesktop<1920Congrat" class="imgBackgroundDesk<1920" alt="imgCongratulations-Desktop<1920" srcset="../../assets/tela3-min(s-e).jpg" media="(min-width: 1366px) and (min-height: 768px) and (orientation: landscape)">
      <source id="imgDesktop1366Congrat" class="imgBackgroundDesk1366" alt="imgCongratulations-Desktop1366" srcset="../../assets/Background_Tela_3(s-e).png" media="(width: 1366px) and (orientation: landscape)">
      <source id="imgDesktop1920Congrat" class="imggroundDesk1" alt="imgCongratulations-Desktop<1920" srcset="../../assets/Background_Tela_3(s-e).png" media="(max-width: 1920px) and (min-height: 937px) and (max-height: 969px) and (orientation: landscape)">
      <source id="imgDesktopCongrat" class="imgBackgroundDesk" alt="imgCongratulations-Desktop" srcset="../../assets/tela3-min(s-e).jpg" media="(max-width: 1024px) and (orientation: landscape)"> -->
      <img id="idImgCongratulations" class="imgCongratulations" alt="Sempre IT - Congratulations" src="../../assets/tela3-min(s-e).jpg" max-width="100%">
    </picture>

    <div class="emailContainer">
      <a href="mailto:marketing@sempreit.com.br" class="email">marketing@sempreit.com.br</a>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Congratulations',

  data() {
    return {
    }
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../style/Congratulations.css';

</style>
