<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <Alert /> -->
    <router-view></router-view>

  </div>
</template>

<script>
import './components/style/Global.css';
//import Alert from './components/Utilities/Alert.vue'

export default {
  name: 'App',
  components: {
    //Alert
  }
}
</script>

<style>
</style>
